@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import "~react-redux-toastr/lib/css/react-redux-toastr.min.css";
@import '~@uppy/core/dist/style.css';
@import '~@uppy/dashboard/dist/style.css';
@import '~@uppy/drag-drop/dist/style.css';
@import '~react-image-lightbox/style.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.loginContainer
{
  width: 500px;
  margin: auto;
  margin-top: 20vh;
}
.site-layout-content {
  background: #fff;
  padding: 24px;
}
.containerContent
{
  min-height: 85vh;
}
#components-layout-demo-top .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}
.ant-layout-header ul li
{
  height: 64px;
  padding-top: 8px;
}
/* START TABLE */
.tableReact {
  width: 100%;
  background: #ffffff;
  color: #212529;
  margin-bottom: 1rem;
  border-collapse: collapse;
  border-spacing: 2px;
}
.theadTableReact {
  background: rgba(222,226,230, 0.5);
}
.theadTableReact tr {
  padding: 0.75rem;
}
.theadTableReact tr th {
  vertical-align: bottom;
  padding: 0.75rem;
  display: table-cell;
}
.tbodyTableReact {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
.tbodyTableReact tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.tbodyTableReact tr th,
.tbodyTableReact tr td {
  padding: 0.75rem;
  vertical-align: top;
}
.rowTableGrey
{
  background: rgba(222,226,230, 0.5);
}
.navPagination
{
  position: absolute;
  right: 10px;
  top: 0px;
}
.paginationTableReact {
  z-index: 1;
  margin-bottom: 30px;
  position: relative;
}
.globalFilterTable {
  float: right;
  margin-bottom: 10px;
}
.inputSearchGlobalFilter
{
  font-size: '1.1rem';
  border: 0ch;
  background: #eff0f2;
}
.inputGoToPageTable
{
  border: 1px solid #dee2e6;
}
.trTableHead
{
  border-bottom: 2px solid white;
}
.headerDatatableClassCenter
{
  text-align: center;
}
/* END TABLE */

.containerButtonAction {
  display: block;
  text-align: center;
}
/* START MODAL */
.buttonCloseModal {
  position: absolute;
  right: 5px;
  font-family: poppins-semibold;
  background: no-repeat;
  border: none;
  color: white;
  font-size: 20px;
  top: 8px;
}
.buttonCloseModal:hover,
.buttonCloseModal:active,
.buttonCloseModal:focus {
  position: absolute;
  right: 5px;
  font-family: poppins-semibold;
  background: no-repeat;
  border: none;
  color: #ffffff;
  font-size: 20px;
  top: 8px;
}
@keyframes modalFade {
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.modalStyleContainer {
  animation-name: modalFade;
  animation-duration: 0.3s;
  top: 35px;
}
.modalStyleContainer section {
  max-height: 85vh;
  overflow-y: hidden;
  background: transparent;
}
.headerModal {
  color: white !important;
  padding-left: 25px;
  height: 50px;
  line-height: 48px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  text-align: center;
  border-bottom: 1px solid #ecf0f3;
}
.headerModal .headerTitle {
  font-weight: bold;
  color: white !important;
}
.modalStyleContainer {
  animation-name: modalFade;
  animation-duration: 0.3s;
  top: 35px;
}
.contentModal {
  padding: 20px;
  padding-bottom: 0;
  background: #ffffff;
  max-height: 70vh;
  overflow-x: auto;
}
.contentModalBig {
  padding: 20px;
  padding-bottom: 0;
  background: #ffffff;
  max-height: 95vh;
  overflow-x: auto;
}

.footerModal {
  padding-left: 10px;
  padding-right: 10px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-top: 10px !important;
  text-align: center;
  background: #ffffff;
}

.btnSubmitModalRoundedGrey {
  width: 180px;
  background: #3e3e3e;
  border: 1px #3e3e3e solid;
  color: white;
}

.btnSubmitModalRoundedRed {
  width: 180px;
  background: #ffe2e5;
  border: none;
  color: #f64e60;
}

.btnSubmitModalRoundedBlue {
  width: 180px;
  background: #527ed6;
  border: 1px #527ed6 solid;
}
/* END MODAL */
.imgPreviewUppy {
  height: 100px;
  margin: 20px 0px 20px 0px;
  border: #dee2e6 solid 1px;
}
.colBtnSearchPeriod
{
  position: relative;
  margin-top:34px;
}